import React from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
//import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux';
import Home from './app/local/Home';
import Login from './app/Login';
import NotFound from './app/NotFound'
import Users from './component/Users';
import AddNew from './pro/AddNew';
import { checkLoggedIn } from './app/states/actions/loginActions';
import Base from './pro/Base';
import Axios from 'axios';
import i18n, { setLang } from './app/lang';
import { changeAppLang } from './app/states/actions/appActions';
import NotLoggedInRoutes from './app/NotLoggedInRoutes';
import { SUBFOLDER } from './app/local/config';
import History from './app/tools/History';
import MultiAdd from './pro/MultiAdd';

    import Exercises from './app/local/Exercises';
    import UsersSys from './app/local/UsersSys';
    import DataDet from './app/local/DataDet';
    import Muscles from './app/local/Muscles';
    import LocalStorage from './app/local/LocalStorage';
    import ProcessLog from './app/local/ProcessLog';
    import Packages from './app/local/Packages';
    import NotesDev from './app/local/NotesDev';
    import Branches from './app/local/Branches';
    import GymTools from './app/local/GymTools';
    import GData from './app/local/GData';
    import Addresses from './app/local/Addresses';
    import ClientsSys from './app/local/ClientsSys';

class App extends Base {
  constructor(props) {
      super(props);
      this.renderLang();
  }
  clearCacheData = () => {
    console.log("clear");
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
   
  }
  componentWillMount = async () => {


    let res = await Axios.get(this.url + "/version").then(res => {

      const version = localStorage.getItem("version")
      if (version) {
        if (version === res.data.site) {
          console.log("ok")
        } else {
          localStorage.setItem("version", res.data.site)
          this.clearCacheData()
          setTimeout(() => window.location.reload(), 5000)

        }
      } else {
        localStorage.setItem("version", res.data.site)
      }
    }
    )
      this.props.checkLoggedIn();
      this.getUpdateData();
      setInterval(() => {
          //  if(this.props.isLoggedIn) {
          this.getUpdateData();
          //  }
      }, 20000);
  }
  renderLang = () => {
    let location = History.location.pathname.split("/");
    if (location[2] && (location[2] === "ar" || location[2] === "en")) {
      this.setLanguage(location[2])
    } else if (location[1] && (location[1] === "ar" || location[1] === "en")) {
      this.setLanguage(location[1])
    } else {
      // alert()
      setLang("ar")
      this.props.changeAppLang("ar")
      window.location = SUBFOLDER + "/ar"
    }
  }
  setLanguage = (currentLang) => {
    if (currentLang) {
      if (currentLang === "ar" || currentLang === "en") {
        setLang(currentLang);
        this.lang = i18n.language
      }
      if (currentLang === "ar") {
        document.getElementById("body").style.direction = "rtl"
        document.getElementById("body").style.textAlign = "right"
      } else {
        document.getElementById("body").style.direction = "ltr"
      }
      this.props.changeAppLang(currentLang);
    }
  }
  getUpdateData = async () => {
      let url = `${this.url}/g/checklists`;
      let accessToken = await localStorage.getItem("b410f8e3c7d17f499f57fc052f777c3b");
      let datalife = {accessToken:accessToken}
      //   console.log(tablesUpdate);
      let res = await Axios.post(url, datalife).then(res => res)
          .catch((error) => "error");
      if (res === "error") {
          console.log(res)
      } else {
          res = res.data
          let data = res.result
          let data2 = res.localData;
          if (res)
          if (data.length > 0)
            if (data2.length>0)
              data2.forEach((pages, index) => {
  
                localStorage.setItem("getUpdateData" + pages.name, JSON.stringify(data[index]));
                let data3 = data[index];

        
  
                let data4 = []
                for (let j in data3) {
                  data4.push(data3[j].id)
                  // if(pages.count)
                  // {
                  //   let index3 = pages.field;
                  //   let index = data3[j][pages.index];
                  //   let name = pages.name;
                  //   let count = pages.count;
                  //   if(!dataCount[index3+index])
                  //   {
                  //     dataCount[index3+index]=0;
                  //   }
                  //   dataCount[index3+index]=dataCount[index3+index]+data3[j][count]
                  // }
                }
                localStorage.setItem("getUpdateDataArray" + pages.name, JSON.stringify(data4));
                // localStorage.setItem("getUpdateDataCount"+pages.name, JSON.stringify({0:dataCount}));
                // console.log(localStorage.getItem("getUpdateDataCount"+pages.name))
              }
              )
          if (res.error === true) {
          } else {
          }
      }
  }
  render() {
    let lang = this.props.lang;
    let selectedLang = "";
    if (selectedLang) {
        lang = selectedLang
    }
      if (this.props.checking) {
          return <div style={{ flex: 1, justifyContent: "center", alignItems: "center", textAlign: "center" }}><this.Loading /></div>
      } else if (!this.props.isLoggedIn) {
        return <NotLoggedInRoutes lang={lang}/>
          
      }
      return (
          <Router basename={`${SUBFOLDER}/${lang}`}>
              <Switch>
                  <Route exact path={`/`} component={Home} />
                  {/* <Route exact path={`/:lang/home`} component={Login} /> */}
                  <Route exact path={`/users`} component={Users} />
                  <Route exact path={`/login`} component={Login} />
                  <Route exact path={`/addNew-:page`} component={AddNew} />
                  <Route exact path={`/multiAdd-:page`} component={MultiAdd} />

    <Route exact path={`/exercises`} component={Exercises} />    
    <Route exact path={`/usersSys`} component={UsersSys} />    
    <Route exact path={`/dataDet`} component={DataDet} />    
    <Route exact path={`/muscles`} component={Muscles} />    
    <Route exact path={`/localStorage`} component={LocalStorage} />    
    <Route exact path={`/processLog`} component={ProcessLog} />    
    <Route exact path={`/packages`} component={Packages} />    
    <Route exact path={`/notesDev`} component={NotesDev} />    
    <Route exact path={`/branches`} component={Branches} />    
    <Route exact path={`/gymTools`} component={GymTools} />    
    <Route exact path={`/gData`} component={GData} />    
    <Route exact path={`/addresses`} component={Addresses} />    
    <Route exact path={`/clientsSys`} component={ClientsSys} />    
                  <Route component={NotFound} />
              </Switch>
          </Router>
      );
  }
}
const getState = (state) => {
  return {
      isLoggedIn: state.auth.isLoggedIn,
      checking: state.auth.checking,
      lang: state.app.lang,
  }
}
export default connect(getState, { checkLoggedIn, changeAppLang })(App);
/////////////////////////////////////,,,,,